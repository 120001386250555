<template>
  <div class="batchBox">
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 店长信息
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="20">
        <el-form
          ref="detail"
          label-position="right"
          label-width="140px"
          :rules="rules"
          :model="detail"
        >
          <el-form-item label="店长名称:" prop="shopOwner">
            <el-input v-model="detail.shopOwner" class="w300" maxlength="150" show-word-limit />
          </el-form-item>
          <el-form-item label="性别:">
            <el-radio-group v-model="detail.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系手机号:" prop="shopMobile">
            <el-input v-model="detail.shopMobile" class="w300" placeholder="请填写门店手机号" />
            <div class="phoneTip">联系电话将作为登录系统的账号进行同步设置，请填写真实有效的手机号</div>
          </el-form-item>
          <el-form-item v-if="id">
            <el-button v-if="!amendPas" type="primary" size="mini" @click="amendPassword">修改密码</el-button>
            <el-button v-else type="primary" size="mini" @click="amendPassword">暂不修改密码</el-button>
          </el-form-item>
          <el-form-item label="登录密码:" v-if="amendPas || !id" prop="password">
            <el-input v-model="detail.password" class="w300" show-password placeholder="请填写登录密码" />
          </el-form-item>
          <el-button
            v-if="typePage != 'compile'"
            type="primary"
            plain
            size="mini"
            class="btn"
            @click="submitForm2('detail')"
          >上一步，填写店铺信息</el-button>
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('detail')"
          >完成，提交店铺</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
class Detail {
  shopOwner = "";
  sex = 1;
  shopMobile = "";
  password = "";
}
class Rules {
  shopOwner = [{ required: true, message: "请填写店长名称", trigger: "blur" }];

  shopMobile = [
    { required: true, message: "请填写店长手机号", trigger: "blur" },
    {
      pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
      message: "手机号格式不对",
      trigger: "change"
    }
  ];
  password = [{ required: true, message: "请填写登录密码", trigger: "blur" }];
}
class Options {
  constructor() {
    this.market = [
      { id: 0, name: "全部" },
      { id: 1, name: "选项1" },
      { id: 2, name: "选项2" }
    ]; // 代理人
  }
}
export default {
  props: {
    typePage: {
      type: String,
      default: ""
    },
    dataMsg: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      options: new Options(), // 选项
      active: 0,
      amendPas: false, //修改按钮
      id: ""
    };
  },
  mounted() {
    if (this.dataMsg.id) {
      this.detail = this.dataMsg;
      this.id = this.dataMsg.id;
      this.detail.sex = parseInt(this.dataMsg.sex);
    }
  },
  methods: {
    // 修改登录密码
    amendPassword() {
      this.amendPas = !this.amendPas;
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("getData3", this.detail);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 店铺信息
    submitForm2(formName) {
      this.$emit("setActiveUp", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 270px;
    }
  }
  .rightCon {
    padding: 40px 0 0 30%;
    .btn {
      margin: 20px 0 50px 80px;
    }
    .mainBtn {
      margin: 30px 0 0 80px;
    }
    .phoneTip {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
