<template>
  <div class="content">
    <shopMonitor v-if="detail.id" :dataMsg="detail" type-page="compile" @getData3="getData3" />
  </div>
</template>

<script>
import shopMonitor from "./components/shopMonitor.vue";
import { updateShop } from "@/api/shop";
export default {
  name: "LeadData",
  components: {
    shopMonitor
  },
  data() {
    return {
      active: 0,
      data3: {},
      detail: {}
    };
  },

  mounted() {
    this.detail = this.$route.query;
  },
  methods: {
    // 店长信息
    getData3(e) {
      this.data3 = e;
      this.hint();
    },
    // 提示
    hint(text) {
      this.$confirm(`确定更改此店长吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 提交
    confirm() {
      updateShop({ id: this.$route.query, ...this.data3 }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.$router.push(
          "./shopDetail?activeName=first&id=" +
            this.detail.shopId +
            "&name=" +
            this.detail.shopName
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
